import "./FavoritePokemon.scss";

function FavoritePokemon() {
  return (
    <div className="favorite-pokemon">
      <p>Favorite Pokemon</p>
    </div>
  );
}

export default FavoritePokemon;
