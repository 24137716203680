import "./App.scss";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { Router, Redirect } from "@reach/router";
import PokemonList from "../components/@PokemonList";
import FavoritePokemon from "../components/@FavoritePokemon";
import Profile from "../components/@Profile";
import PokemonDetails from "../components/@PokemonDetails";

function App() {
  return (
    <div className="root">
      <Header />
      <div className="main-content">
        <Sidebar />
        <Router>
          <PokemonList path="/" />
          <FavoritePokemon path="/favorites" />
          <Profile path="/profile" />
          <PokemonDetails path="/pokemon/:pokemonId" />
          <Redirect from="*" to="/" noThrow />
        </Router>
      </div>
    </div>
  );
}

export default App;
