import "./Sidebar.scss";
import { Link } from "@reach/router";

const NavLink = ({ children, ...props }) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        style: {
          backgroundColor: isCurrent ? "rgb(197, 0, 36)" : null,
          boxShadow: isCurrent ? "1px 0px 6px 1px var(--shadow)" : null,
          color: isCurrent ? "var(--white)" : null
        }
      };
    }}
  >
    <div className="link-text">{children}</div>
  </Link>
);

function Sidebar() {
  return (
    <div className="sidebar">
      <div className="section-container">
        <p className="section-header">Explore</p>
        <NavLink className="section-link" to="/">
          Pokédex
        </NavLink>
      </div>
      <div className="section-container">
        <p className="section-header">My Stuff</p>
        <NavLink className="section-link" to="/favorites">
          Favorites
        </NavLink>
      </div>
    </div>
  );
}

export default Sidebar;
