import "./PokemonDetails.scss";

function PokemonDetails({ pokemonId }) {
  return (
    <div className="pokemon-details">
      <p>PokemonDetails: {pokemonId}</p>
    </div>
  );
}

export default PokemonDetails;
