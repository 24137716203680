import "./Profile.scss";

function Profile() {
  return (
    <div className="profile">
      <p>Profile</p>
    </div>
  );
}

export default Profile;
