import { makeAutoObservable, autorun } from "mobx";
import { request } from "../utils";

class PokemonStore {
  constructor() {
    makeAutoObservable(this);
    autorun(() => {
      //Check authenticated (if needed)
      this.fetchPokemon();
    });
  }

  rawPokemon = [];
  searchText = "";

  get pokemon() {
    return this.rawPokemon.filter(p => p.name.includes(this.searchText.toLowerCase().trim()));
  }

  async fetchPokemon() {
    try {
      const pokemon = await request.get("/v1/pokemon");
      this.rawPokemon = pokemon;
    } catch (err) {
      console.warn("Errorzsss fetching pokemon", err);
    }
  }

  setSearchText = text => (this.searchText = text);
}

export default new PokemonStore();
