import { Link } from "@reach/router";
import { FiUser } from "react-icons/fi";
import "./Header.scss";

function Header() {
  return (
    <div className="header">
      <Link to="/profile" className="profile-icon">
        <div className="profile-circle">
          <FiUser size={28} />
        </div>
      </Link>
    </div>
  );
}

export default Header;
