import axios from "axios";
import { API_HOST } from "../constants";

const instance = axios.create({ baseURL: API_HOST });

const makeUnpackedMethod =
  method =>
  async (...params) => {
    const { data } = await method(...params);
    return data;
  };

const wrapper = {
  post: makeUnpackedMethod(instance.post),
  get: makeUnpackedMethod(instance.get),
  put: makeUnpackedMethod(instance.put),
  delete: makeUnpackedMethod(instance.delete)
};

export default wrapper;
