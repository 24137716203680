import { observer } from "mobx-react";
import { useState } from "react";
import { Link } from "@reach/router";
import { FiSearch } from "react-icons/fi";
import PokemonStore from "../../stores/PokemonStore";
import "./PokemonList.scss";

const toTitlecase = word => (word = word.charAt(0).toUpperCase() + word.slice(1));

const Chip = ({ type }) => {
  return (
    <div className="chip" style={{ backgroundColor: `var(--${type})` }}>
      <div className="text">{toTitlecase(type)}</div>
    </div>
  );
};

function PokemonList() {
  const { pokemon, searchText } = PokemonStore || {};
  const [isFocused, setIsFocused] = useState(false);

  const setSearchText = ({ target }) => PokemonStore.setSearchText(target.value);
  const handleFocusChange = ({ type }) => setIsFocused(type === "blur" ? false : true);

  const pokemonList = pokemon.map(pokemon => {
    const chips = pokemon?.types?.map(type => <Chip type={type} />);
    return (
      <Link to={`/pokemon/${pokemon.id}`}>
        <div className="card">
          <div className="image-container" style={{ backgroundColor: `var(--${pokemon.types[0]}-light)` }}>
            <img className="image" src={pokemon.sprite} alt={pokemon.name} />
          </div>
          <div className="info-container">
            <div className="name-id-container">
              <div className="name">{toTitlecase(pokemon.name)}</div>
              <div className="id">#{String(pokemon.id).padStart(3, "0")}</div>
            </div>
            <div className="chip-description-container">
              <div className="chips-container">{chips}</div>
              <div className="description">{toTitlecase(pokemon.types[0])} Pokémon</div>
            </div>
          </div>
        </div>
      </Link>
    );
  });

  return (
    <div className="pokemon-list-page">
      <div className="page-header">
        <div className="title">Pokédex</div>
        <div className="search-container">
          <FiSearch className="icon" size={18} style={{ color: isFocused ? "var(--black)" : "var(--lightGray)" }} />
          <input
            placeholder="Search Pokémon..."
            value={searchText}
            onBlur={handleFocusChange}
            onFocus={handleFocusChange}
            onChange={setSearchText}
            type="text"
            className="search-bar"
          />
        </div>
      </div>
      <div className="pokemon-list">{pokemonList}</div>
    </div>
  );
}

export default observer(PokemonList);
